import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import Layout from "root/components/Layout";
import SEO from "root/components/SEO";
import Navbar from "root/components/Navbar";
import Hero from "root/sections/SolutionHero";
import BlogPostList from "root/sections/BlogPostList";
import Footer from "root/components/Footer";
import Apoios from "root/components/Apoios";
import { useTranslation } from "react-i18next";

function Blog({ location }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  const color = "blue";
  const currentPage = "blog";
  const category = location.state ?? 0;

  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "blog-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 585, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allMdx(sort: { order: DESC, fields: frontmatter___date }) {
        edges {
          node {
            frontmatter {
              path
              author {
                childAuthorsJson {
                  name
                }
              }
              date(formatString: "MMM DD, YYYY", locale: "En")
              title
              description
              category
              keyword
              language
              alt
              featuredImage {
                image: childImageSharp {
                  fluid(maxWidth: 1440, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const edges = data.allMdx?.edges || {};

  return (
    <div>
      <SEO title={t("pages.blog.label")} keywords="" />
      <Layout title="Blog | Sioslife" description="Sioslife's Blog">
        <Navbar currentPage={currentPage} color={color} blueLogin="true" />
        <Hero
          image={data.hero}
          heroStyles={{
            width: { width: "585px" },
            right: { right: "0" },
            mobileWidth: { maxWidth: "585px" },
          }}
          color={color}
          translations={t("blog.hero")}
          currentPage={currentPage}
        />
        <BlogPostList
          translations={t("blog.blogPostList")}
          blogposts={edges}
          clickedCategory={category}
          languageProp="EN"
        />
        <Footer />
        <Apoios />
      </Layout>
    </div>
  );
}
Blog.propTypes = {
  location: PropTypes.func.isRequired,
};

export default Blog;
